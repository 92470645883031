<template>
  <v-card>
    <v-card-title>Welcome to the dashboard!</v-card-title>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Dashboard",

  computed: {
    ...mapState({
      user: state => state.auth.currentUser
    })
  },

  methods: {}
};
</script>
